import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  useInView,
  useAnimation,
  useViewportScroll,
  useTransform,
  useScroll,
} from "framer-motion";
import { useIsDesktop, useIsMobile } from "../hooks/utils";

// CSS styles
import "./company.scss";

import quote from "../../public/svg/quote.svg";

export default ({ className, id }) => {
  const selfRef = useRef(null);
  const [selfTopPos, setSelfTopPos] = useState(null);

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  const { scrollY, scrollX } = useScroll();

  const citation_variants = {};
  {
    const xTransformD = useTransform(scrollY, [0, selfTopPos], [-500, 0]);
    const xTransformM = useTransform(scrollY, [0, selfTopPos - 350], [500, 0]);
    const opacityTransform = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 25, selfTopPos + 200],
      [0, 1, 1, 0]
    );

    citation_variants.x = isMobile && xTransformM;
    citation_variants.opacity = opacityTransform;
  }

  const image_variants = {};
  {
    const xTransform = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [-500, 0, 0, 500]
    );
    const yTransform = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [350, 0, 0, -350]
    );
    const opacityTransform = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [0, 1, 1, 0]
    );

    image_variants.x = isMobile ? xTransform : 0;
    image_variants.y = isDesktop ? yTransform : 0;
    image_variants.opacity = opacityTransform;
  }

  const text_variants = {};
  {
    const xTransformD = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [500, 0, 0, -500]
    );
    const xTransformM = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [500, 0, 0, -500]
    );
    const opacityTransform = useTransform(
      scrollY,
      [0, selfTopPos, selfTopPos + 350, selfTopPos * 2],
      [0, 1, 1, 0]
    );

    text_variants.x = isMobile ? xTransformM : xTransformD;
    text_variants.opacity = opacityTransform;
  }

  // const ref = useRef(null);
  // const isInView = useInView(ref, { triggerOnce: true });
  // const mainControls = useAnimation();

  // const { scrollY, scrollX } = useScroll();
  // let variants = { y: 0, x: 0, opacity: 1 };
  // variants.opacity =
  //   isDesktop && useTransform(scrollY, [0, selfTopPos], [0, 1], "clamp");
  // variants = isDesktop && {
  //   y: useTransform(scrollY, [0, selfTopPos], [0, 0], "clamp"),
  //   x: useTransform(scrollY, [0, selfTopPos], [-350, 0], "clamp"),
  //   opacity: useTransform(scrollY, [0, selfTopPos], [0, 1], "clamp"),
  // };

  // const y = useTransform(scrollY, [0, selfTopPos], [350, 0], "clamp");
  // const x = useTransform(scrollY, [0, selfTopPos], [-350, 0], "clamp");
  // const opacity = useTransform(scrollY, [0, selfTopPos], [0, 1], "clamp");

  // useEffect(() => {
  //   if (isInView) {
  //     mainControls.start("visible");
  //   } else {
  //     mainControls.start("hidden");
  //   }
  //   console.log("isInView", isInView);
  // }, [isInView]);

  useEffect(() => {
    const rect = selfRef.current.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setSelfTopPos(rect.top + scrollTop);
  }, [selfRef]);

  return (
    <section ref={selfRef} className={className} id={id}>
      <div className="content--wrapper">
        <div id="company--quote" className="content--row content--row--dark">
          {/* <div className="content--row--mfull"> */}
          <motion.div
            className="content--col--right--rfull"
            style={citation_variants}
          >
            <p className="text--quote">
              <img className="double_quotes" src={quote} alt="Double quotes" />
              Le Quetzal, l’oiseau tropicale est un véritable symbole de liberté
              puisqu’une fois en captivité, il se laisse mourir plutôt que de
              vivre enfermé.
            </p>
          </motion.div>
        </div>
        <div
          id="company--description"
          className="content--row--mfull content--row--light"
        >
          {/* <div className="content--row--"> */}
          <div className="content--col--left--rfull">
            <motion.div
              className="image-container"
              style={image_variants}
              // variants={{
              //   hidden: { opacity: 0, y: "50vh" },
              //   visible: { opacity: 1, y: 0 },
              // }}
              // initial="hidden"
              // animate={mainControls}
              // transition={{ duration: 0.5 }}
            ></motion.div>
          </div>
          <div className="content--col--right--rfull">
            <motion.div className="text--company" style={text_variants}>
              <h1>La compagnie</h1>
              <p>
                <b>Quetzal Art</b> est une compagnie de spectacle vivant fondée
                par <b>Elodie Allary</b> en 2023 dans une volonté de mélanger
                plusieurs arts.
                <br />
                <br />
                D’origine franco-guatémaltèque, Elodie trouve son essence
                artistique à la croisée des cultures. Ainsi, elle crée sa
                compagnie à l’image d’un berceau de plusieurs rencontres
                favorisant l’hybridation. En parallèle de la diffusion de son
                solo « Morpho » ainsi que du ballet pantomime “le Secret de
                Myrto”, la compagnie entame différentes créations en
                collaboration avec d’autres artistes mélangeant danse classique,
                contemporaine, hip-hop, musique, chant et arts visuels.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
