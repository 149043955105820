module.exports = [
  {
    "id": "eall",
    "firstname": "Elodie",
    "lastname": "Allary",
    "roles_short": [" Directrice artistique", "Chorégraphe"],
    "roles": [
      " Directrice artistique",
      "Chorégraphe",
      "Danseuse",
      "Autre",
      "Blabla"
    ],
    "picture": "eall",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/elodie.allary"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/elodie.allary/"
      }
    ],
    "description": "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor suscipit tempora assumenda temporibus eos eius quia distinctio quasi incidunt! Fuga voluptates qui tenetur accusantium blanditiis. Odio laborum obcaecati ullam voluptatum.</p><br/><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor suscipit tempora assumenda temporibus eos eius quia distinctio quasi incidunt! Fuga voluptates qui tenetur accusantium blanditiis.</p>"
  },
  {
    "id": "fcap",
    "firstname": "Franck",
    "lastname": "Caporale",
    "roles_short": ["Co-Chorégraphe", "Danseur"],
    "roles": ["Co-Chorégraphe", "Danseur"],
    "picture": "fcap",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/kifran.basterd"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/franck_caporale/"
      }
    ]
  },
  {
    "id": "edob",
    "firstname": "Emmanuel",
    "lastname": "Dobby",
    "roles_short": ["Assistant chorégraphe", "Danseur"],
    "roles": ["Assistant chorégraphe", "Danseur"],
    "picture": "edob",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/emmanuel.dobby"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/dcr_emmanueldobby/"
      }
    ]
  },
  {
    "id": "jtou",
    "firstname": "Jade",
    "lastname": "Tournes",
    "roles_short": ["Compositrice, Chanteuse"],
    "roles": ["Compositrice, Chanteuse"],
    "picture": "jtou",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/jade.tourneboudin.1"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/the.mad.made/"
      },
      {
        "name": "soundcloud",
        "url": "https://soundcloud.com/#"
      }
    ]
  },
  {
    "id": "tpal",
    "firstname": "Théo",
    "lastname": "Palfray",
    "roles_short": ["Compositeur"],
    "roles": ["Compositeur"],
    "picture": "tpal",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/theo.palfray"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/theo_palfray9/"
      },
      {
        "name": "soundcloud",
        "url": "https://soundcloud.com/#"
      }
    ]
  },
  {
    "id": "jevr",
    "firstname": "Jules",
    "lastname": "Evrard",
    "roles_short": ["Compositeur"],
    "roles": ["Compositeur"],
    "picture": "jevr",
    "social": [
      {
        "name": "instagram",
        "url": "https://www.instagram.com/d.s.t.c.h/"
      },
      {
        "name": "playlist",
        "url": "https://recordu.lnk.to/D0t7Fe"
      }
    ]
  },
  {
    "id": "wpen",
    "firstname": "William",
    "lastname": "Penanhoat",
    "roles_short": ["Régisseur Lumière"],
    "roles": ["Régisseur Lumière"],
    "picture": "wpen",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/william.pnh"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/williampnh/"
      }
    ]
  },
  {
    "id": "jleg",
    "firstname": "Jérémy",
    "lastname": "Legeay",
    "roles_short": ["Développeur", "Assistant Vidéo"],
    "roles": ["Développeur", "Assistant Vidéo"],
    "picture": "jleg",
    "social": []
  },
  {
    "id": "spol",
    "firstname": "Studio",
    "lastname": "Polymorphose",
    "roles_short": ["Photographe"],
    "roles": ["Photographe"],
    "picture": "spol",
    "social": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/sam.polymorphose"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/polymorphose_photo/"
      }
    ]
  }
]
;