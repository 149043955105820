import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player";
// import FsLightbox from "fslightbox-react";

// CSS styles
import "./projects.scss";

// Project import
import CommeUnEcho from "./CommeUnEcho";
import Iceberg from "./Iceberg";

export default ({ className, id }) => {
  // const containerRef = React.createRef();
  // const [toggler, setToggler] = useState(false);
  // const [photoIndex, setPhotoIndex] = useState(-1);
  // const [photos, setPhotos] = useState([]);

  // useEffect(() => {
  //   setPhotos(Object.values(pictures).sort());
  // }, [pictures]);

  return (
    // <section className={className} id={id} ref={containerRef}>
    <section className={className} id={id}>
      <div className="content--wrapper">
        <h1>Les créations</h1>
      </div>
      <Iceberg />
      <CommeUnEcho />
    </section>
  );
};
