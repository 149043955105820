import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import FsLightbox from "fslightbox-react";

// CSS styles
import "./iceberg.scss";

// Images
import logo_iceberg from "../../public/projects/iceberg/logo__iceberg.svg";

// Videos
import teaser from "../../public/projects/iceberg/teaser_iceberg.mp4";
import teaser_placeholder from "../../public/projects/iceberg/placeholder_teaser.jpg";

//Gallery
import tb_pictures from "../../public/projects/iceberg/g/tb/iceberg-*.jpg";
import pictures from "../../public/projects/iceberg/g/iceberg-*.jpg";

export default () => {
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos(Object.values(pictures).sort());
  }, [pictures]);

  return (
    <>
      <div id="iceberg" className="content--wrapper">
        <div id="project--video" className="content--row--full">
          {/* <h1>Les créations</h1> */}
          <div className="video--wrapper">
            {/* <ReactPlayer url={teaser} /> */}
            <video
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              poster={teaser_placeholder}
            >
              <source src={teaser} type="video/mp4" />
            </video>
            <div className="project--title--wrapper">
              <div className="project--title-creation">Création</div>
              <img className="project--title" src={logo_iceberg} alt="" />
              <h5 className="project--subtitle text--primary">
                Trio Néo-Classique / Breakdance / Contemporain
              </h5>
              <div className="project--time">Tout public - 35 min</div>
            </div>
          </div>
        </div>
        <div id="project--description" className="content--row--mfull">
          <div className="content--col--left--rfull">
            <div className="text--project">
              <h3 className="project--title">Iceberg</h3>
              <h5 className="project--subtitle text--primary">
                Trio dance contemporaine / Chant live
              </h5>
              <div className="project--time">Tout public - 35 min</div>
              <div className="project--description">
                <p>
                  Si la partie émergée de l’iceberg représente ce que l’on est
                  face à la société, qu’arriverait-il si nous pouvions voir
                  apparaître la lueur mystérieuse de notre être enfoui ?
                </p>
                <p>
                  Dans ses profondeurs, la création “Iceberg” interroge la
                  thématique de “l’identité” à travers un trio pour deux
                  danseurs et une chanteuse. Sur scène, les chants parfois
                  murmurés et fredonnés viendront instaurer une ambiance
                  minimaliste et cristalliser les instants d'unisson reflétant
                  le collectif, une "voix commune". Au milieu de cet inlandsis,
                  les trois corps s'entremêlent pour parfois s'isoler et mettre
                  en lumière la singularité de chacun à travers des solos, duos.
                </p>
                <p>
                  "Iceberg" traite aussi du décloisonnement des différentes
                  disciplines artistiques dans une esthétique androgyne. Faire
                  ressortir les traits de caractère les plus forts de chaque
                  interprète, la force féminine, la douce masculinité. Dans son
                  souffle glacé, ce spectacle nous transporte dans un univers de
                  relief aux abysses immaculés.
                </p>
              </div>
            </div>
          </div>
          <div className="content--col--right--rfull">
            <div className="image-container"></div>
          </div>
        </div>
        <div
          id="project--gallery--wrapper"
          className="content--row--full content--row--dark"
        >
          <FsLightbox
            toggler={toggler}
            sources={photos}
            slide={photoIndex + 1}
          />
          <h2 className="text--light">Gallerie</h2>
          <div id="project--gallery">
            {photos.length > 0 &&
              photos.map((photo, index) => {
                return (
                  <div key={index} className="project--gallery--img--wrapper">
                    <img
                      key={index}
                      src={tb_pictures[index + 1]}
                      alt={`comme_un_echo-${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setToggler(!toggler);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div
          id="project--legal-notices--wrapper"
          className="content--row--full content--row--dark"
        >
          <b>Direction artistique</b>Elodie Allary
          <b>Chorégraphie | Interprétation | Chant live</b>Elodie Allary,
          Emmanuel Dobby
          <b>Composition musicale | Interprétation | Chant live</b>Jade Tournes
          <b>Création Lumière</b>William Penanhoat
        </div>
      </div>
    </>
  );
};
