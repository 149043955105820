import React, { useEffect, useState } from "react";

// CSS styles
import "./member.scss";

// Pictures
const pictures_list = require(`../../public/team/*.jpg`);

// Team members
const _teamMembers = require("./members");

// Icons
import { FaFacebook, FaInstagram, FaSoundcloud, FaMusic } from "react-icons/fa";

const _SOCIAL_NETWORKS = {
  facebook: FaFacebook,
  instagram: FaInstagram,
  soundcloud: FaSoundcloud,
  playlist: FaMusic,
};

export default ({ className, id, memberDetail }) => {
  const { firstname, lastname, roles, description, picture, social } =
    memberDetail;

  return (
    <section className={`${className}`} id={id}>
      <div className="content--wrapper">
        <img
          src={picture ? picture : "https://via.placeholder.com/290x390"}
          alt={`${firstname} ${lastname}`}
        />
        <div className="member--title--wrapper">
          <h3>{`${firstname} ${lastname}`} </h3>
          <ul>
            {roles &&
              roles.map((role, key) => {
                return <li key={key}>{role}</li>;
              })}
          </ul>
        </div>
        <div
          className="member--description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <div className="member--social">
          {social &&
            social.map((network, key) => {
              const IconComp = _SOCIAL_NETWORKS[network.name];
              return (
                <a key={key} href={network.url} target="_blank">
                  <IconComp />
                </a>
              );
            })}
        </div>
      </div>
    </section>
  );
};
