import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Member from "./Member";

// CSS styles
import "./team.scss";

// Pictures
const _pictures_list = require(`../../public/team/*.jpg`);

// Team members
const _teamMembers = require("./members");

// Icons
import { FaFacebook, FaInstagram, FaSoundcloud, FaMusic } from "react-icons/fa";

const _SOCIAL_NETWORKS = {
  facebook: FaFacebook,
  instagram: FaInstagram,
  soundcloud: FaSoundcloud,
  playlist: FaMusic,
};

const MemberDetail = ({ i, expanded, setExpanded, className, member }) => {
  const isOpen = i === expanded;

  return (
    <div className={className} onClick={() => setExpanded(isOpen ? false : i)}>
      <img
        src={
          member.picture && _pictures_list[member.picture]
            ? _pictures_list[member.picture]
            : "https://via.placeholder.com/290x390"
        }
        alt={`${member.firstname} ${member.lastname}`}
      />
      <div className="team--members--legend">
        <h3>{`${member.firstname} ${member.lastname}`} </h3>
        <ul>
          {member.roles_short &&
            member.roles_short.map((role, key) => {
              return <li key={key}>{role}</li>;
            })}
        </ul>

        <AnimatePresence initial={false}>
          {isOpen && member.description && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                collapsed: { maxHeight: 0 },
                open: { maxHeight: "1000vh" },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div
                className="team--members--description"
                dangerouslySetInnerHTML={{ __html: member.description }}
                variants={
                  {
                    // collapsed: { opacity: 0, maxHeight: 0 },
                    // open: { opacity: 1, maxHeight: "1000vh" },
                  }
                }
                transition={{ duration: 0.8 }}
              ></div>
            </motion.section>
          )}

          <div className="team--members--social">
            {member.social &&
              member.social.map((network, key) => {
                const IconComp = _SOCIAL_NETWORKS[network.name];
                return (
                  <a key={key} href={network.url} target="_blank">
                    <IconComp />
                  </a>
                );
              })}
          </div>
        </AnimatePresence>
      </div>
    </div>
  );

  {
    /* <motion.header
        initial={false}
        animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
        onClick={() => setExpanded(isOpen ? false : i)}
        style={{ height: 40, backgroundColor: "red" }}
      />
      {isOpen && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
        >
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt
          laborum odit laboriosam vel tempora saepe ea vero voluptates. Libero
          atque est eos consequatur voluptates culpa dolorem at blanditiis ipsum
          quas?
        </motion.div>
      )} */
  }
  {
    /* <motion.div
      className={className}
      variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
      transition={{ duration: 0.8 }}
    >
      {" "}
      {children}{" "}
    </motion.div> */
  }
  // </AnimatePresence>
  // );
};

export default ({ className, id }) => {
  const [currMember, setCurrMember] = useState(_teamMembers[0]);
  const [expanded, setExpanded] = useState(null);

  // useEffect(() => {
  //   const allDesc = document.getElementsByClassName(
  //     "team--members--description"
  //   );
  //   for (const key in allDesc) {
  //     if (Object.hasOwnProperty.call(allDesc, key)) {
  //       const elem = allDesc[key];
  //       elem.style.maxHeight = 0;
  //     }
  //   }
  // }, []);

  // const _onMemberSelection = (member_selected) => {
  //   setCurrMember(member_selected);
  // };

  // const _onMemberClick = (e) => {
  //   const selected = e.target.closest(".team--members--item");
  //   const desc = selected.getElementsByClassName(
  //     "team--members--description"
  //   )[0];

  //   const allDesc = document.getElementsByClassName(
  //     "team--members--description"
  //   );

  //   for (const key in allDesc) {
  //     if (Object.hasOwnProperty.call(allDesc, key)) {
  //       const elem = allDesc[key];
  //       elem.style.maxHeight = 0;
  //     }
  //   }

  //   desc.style.maxHeight = "100%";

  //   console.log(allDesc);
  // };

  return (
    <>
      <section className={className} id={id}>
        <div className="content--wrapper">
          <div className="page--separator--m"></div>
          {/* <div className="team--wrapper"> */}
          <div className="team--title">
            <h2 className="text--light">L'équipe</h2>
          </div>
          <div className="team--members--list">
            {_teamMembers.map((member, index) => {
              return (
                <MemberDetail
                  className="team--members--item"
                  key={index}
                  member={member}
                  i={index}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  onClick={() => setExpanded(isOpen ? false : i)}
                />
              );
              // return (
              //   <Accordion
              //     className="team--members--item"
              //     key={index}
              //     i={index}
              //     expanded={expanded}
              //     setExpanded={setExpanded}
              //   >
              //     {/* <div
              //     className="team--members--item"
              //     key={index}
              //     onClick={(e) => {
              //       // _onMemberClick(e);
              //       // _onMemberSelection(member);
              //     }}
              //   > */}
              {
                /* <img src={member.picture} alt={`${member.firstname} ${member.lastname}`} /> */
              }

              //   </Accordion>
              //   // </div>
              // );
            })}
          </div>
          {/* </div> */}
        </div>
      </section>
      {/* {currMember && (
        <Member
          className="fullpage"
          id={`member_detail_${currMember.id}`}
          memberDetail={{
            ...currMember,
            picture: _pictures_list[currMember.picture],
          }}
        />
      )} */}
    </>
  );
};
